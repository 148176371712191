.container {
  background-color: #141516;
  width: 420px;
  border-radius: 20px 20px 30px 30px;
  padding-top: 19px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    width: unset;
    width: 100%;
    max-width: 420px;
  }
  @media (max-width: 1071px) {
    padding-top: 35px;
  }
}

.containerWithBanner {
  padding-top: 0;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 160px;
  margin: 0 20px 0px 20px;
}

.name {
  font: 25px/28px var(--visby-medium);
  color: #ffffff;
  @media (max-width: 600px) {
    margin: 5px 0 0 0;
  }
  @media (max-width: 465px) {
    font-size: 21px;
  }
}

.profileImage {
  border-radius: 10px;
}

.studentCredidentals {
  display: flex;
  flex-direction: column;
}

.studentCredidentalsWithPhoto {
  display: grid;
  grid-template-columns: 84px auto;
  gap: 11px;
}

.position {
  margin-top: 5px;
  font: 13px/21px var(--visby);
  color: #ffffff;
}

.personalInformation {
  max-width: 346px;
  min-height: 39px;
  font: 13px var(--visby);
  color: #ffffff;
  margin-left: 10px;
  margin-top: 5px;
  @media (max-width: 600px) {
    font-size: 12px;
    margin-right: 10px;
  }
  @media (max-width: 355px) {
    margin-right: 5px;
  }
  @media (max-width: 450px) {
    font-size: 11px;
    max-width: 240px;
  }
  @media (max-width: 415px) {
    max-width: 200px;
  }
  @media (max-width: 361px) {
    max-width: 180px;
  }
}

.linkedinLogo {
  filter: brightness(0) invert(1);
}
.mailLogo {
  filter: brightness(0) invert(1);
}

.personalInformationWrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 4px;
  @media (max-width: 400px) {
    flex-direction: column-reverse;
  }
}

.iconWrapper {
  display: flex;
  @media (max-width: 400px) {
    div > a {
      margin-left: 0px;
    }
  }
}

.bottomWrapper {
  background-color: #0a0a0a;
  border-radius: 0 0 20px 20px;
  width: 100%;
  bottom: 0px;
  padding: 20px 10px 21px 20px;
  margin-top: 14px;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: 460px) {
    padding: 20px 10px 21px 10px;
  }
}

.bottomInfoWrapper {
  font: 16px var(--visby-medium);
  color: #ffffff;
  width: 378px;
  overflow: hidden;
  display: flex;
  justify-items: flex-start;
  gap: 8px;
  @media (max-width: 460px) {
    width: 365px;
  }
  @media (max-width: 450px) {
    width: 355px;
  }
  @media (max-width: 440px) {
    width: 340px;
  }
  @media (max-width: 430px) {
    width: 335px;
  }
  @media (max-width: 420px) {
    width: 330px;
  }
  @media (max-width: 410px) {
    width: 320px;
  }
  @media (max-width: 395px) {
    width: 310px;
  }
  @media (max-width: 380px) {
    width: 300px;
  }
  @media (max-width: 370px) {
    width: 290px;
  }
  @media (max-width: 360px) {
    width: 280px;
  }
  @media (max-width: 350px) {
    width: 270px;
  }
  @media (max-width: 340px) {
    width: 255px;
  }
  @media (max-width: 330px) {
    width: 245px;
  }
  @media (max-width: 320px) {
    width: 240px;
  }
}

.scrollwrapper {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.arrow::before {
  content: "";
  position: absolute;
  right: 0;
  width: 60px;
  height: 30px;
  left: calc(100% - 66px);
  background: linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent);
  @media (max-width: 460px) {
    width: 59px;
  }
  @media (max-width: 440px) {
    width: 56px;
  }
  @media (max-width: 430px) {
    width: 60px;
  }
  @media (max-width: 340px) {
    width: 58px;
  }
}
.bottomInfoWrapper::-webkit-scrollbar {
  display: none;
}

.arrowLeft {
  transform: scaleX(-1);
  display: flex;
  align-items: center;
}
.arrow {
  display: flex;
  align-items: center;
}

.arrowLeftShadow::after {
  content: "";
  position: absolute;
  right: 0;
  width: 58px;
  height: 30px;
  left: calc(100% - 64px);
  background: linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent);
  @media (max-width: 440px) {
    width: 56px;
  }
  @media (max-width: 430px) {
    width: 58px;
  }
}

.arrowTechSkillsLeftShadow::after {
  content: "";
  position: absolute;
  right: 0;
  width: 60px;
  height: 30px;
  left: calc(100% - 66px);
  background: linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent);
  @media (max-width: 460px) {
    width: 60px;
  }
}

.arrowHiden {
  display: none;
}
.arrowHidenTechSkills {
  display: none;
}

.arrowHidenLeft {
  pointer-events: none;
  filter: brightness(0%);
}

.arrowTechSkillsHidenLeft {
  pointer-events: none;
  filter: brightness(0%);
}

.profesionalExpierence {
  margin: 7px 0 7px 0;
  font: 15px var(--visby);
}

.profilePicture {
  border-radius: 100px;
}

.extraInformation {
  background-color: #00fca3;
  border-radius: 20px;
  color: #0a0a0a;
  font: 9px var(--visby-bold);
  padding: 3px 10px 4px 7px;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  span {
    margin-top: 2px;
  }
}

.extraInformationBanner {
  margin-bottom: 20px;
  display: flex;
  position: relative;
  justify-content: space-around;
}

.technicalSkillsWrapper {
  background-color: #161616;
  color: #8b8b8b;
  padding: 2px 5.5px 2px 5.5px;
  border-radius: 5px;
  white-space: nowrap;
}

.description {
  margin-top: 31px;
  color: #ffffff;
  font: 11px/17px var(--visby);
  position: relative;
}

.descriptionWithShadow::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 380px;
  height: 40px;
  right: calc(100% - 380px);
  background: linear-gradient(360deg, #141516 0%, rgba(10, 10, 10, 0) 100%);

  @media (max-width: 460px) {
    width: 365px;
  }
  @media (max-width: 440px) {
    width: 345px;
  }
  @media (max-width: 420px) {
    width: 320px;
  }
  @media (max-width: 385px) {
    width: 300px;
  }
  @media (max-width: 365px) {
    width: 285px;
  }
  @media (max-width: 350px) {
    width: 265px;
  }
  @media (max-width: 325px) {
    width: 245px;
  }
}

.showAllText {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.textContainer {
  height: 50px;
  overflow: hidden;
}

.textContainerFull {
  height: auto;
}

.textContainerForShortDesc {
  @media (max-width: 470px) {
    height: auto;
  }
}
