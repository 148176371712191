.wrapper {
  padding: 21px 30px 33px 30px;
  width: 455px;
  height: 332px;
  background-color: #141415;
  border-radius: 20px;
  @media (max-width: 1075px) {
    height: unset;
  }
  @media (max-width: 600px) {
    width: unset;
    max-width: 486px;
    width: 100%;
  }
}

.title {
  color: #ffffff;
  font: 19px var(--visby-semiBold);
}

.linkContainer {
  margin-top: 23px;
}

.linkWrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 17px 16px;
  background-color: #262627;
  border-radius: 7px;
  height: 64px;
  @media (max-width: 390px) {
    flex-direction: column;
    gap: 8px;
    height: unset;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.desc {
  margin-left: 15px;
  font: 10px/18px var(--visby-semiBold);
  color: #ffffff;
}

.highLight {
  font: 16px/11px var(--visby-semiBold);
}
