.wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 114px;
  gap: 20px;

  @media (max-width: 850px) {
    flex-direction: column;
    margin-top: 0;
    gap: 39px;
  }
  @media (max-width: 580px) {
    margin-top: 0;
  }
}

.headWrapper {
  max-width: 400px;
  width: 100%;
  z-index: 2;
  min-height: 300px;
}

.title {
  font: 42px/46px var(--visby-bold);
  color: #ffffff;

  @media (max-width: 580px) {
    font-size: 32px;
    line-height: 36px;
  }
}

.descWrapper {
  margin-top: 12px;
}

.desc {
  font: 17px/26px var(--visby-medium);
  color: #ffffff;
}

.highLight {
  font: 17px/19px var(--visby-bold);
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  max-width: 400px;
}

.cardWrapper {
  width: 414px;
  height: 240px;
  border-radius: 20px;
  background-color: rgba(20, 21, 22, 0.8);
  z-index: 2;
  padding: 33px 34px 38px 30px;

  @media (max-width: 460px) {
    width: 100%;
    height: 100%;
    padding: 26px 19px 34px 23px;
  }
}

.repeatButtonWrapper {
  width: 226px;
  transition: all 0.2s;

  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
  @media (max-width: 460px) {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    position: unset;
  }
}

.mailButtonWrapper {
  margin-top: 20px;
  a {
    color: #1c1f23;
    font: 14px/21px var(--visby-bold);
    padding: 10px 16px;
    width: 226px;
    height: 42px;
    background-color: #00fca3;
    border-radius: 20px;
    transition: all 0.2s;
    display: inline-block;
    text-align: center;

    &:hover {
      color: #1c1f23;
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      color: #1c1f23;
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.repeatButtonWrapper {
  margin-top: 26px;
  span {
    color: #00fca3;
  }
}

.authWrapper {
  margin-top: 26px;
}
