.container {
  max-width: 1010px;
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 20px;
  margin-top: 25px;
  @media (max-width: 1060px) {
    grid-template-rows: auto auto auto;
    column-gap: 0px;
  }
}

.adjustedContainer {
  @media (max-width: 920px) {
    grid-template-columns: 1fr;
  }
}

.title {
  font: 52px/58px var(--visby-medium);
  color: #ffffff;
  margin-bottom: 53px;
  @media (max-width: 770px) {
    font-size: 45px;
    margin-bottom: 25px;
  }
  @media (max-width: 465px) {
    font-size: 35px;
    line-height: 45px;
  }
  @media (max-width: 370px) {
    font-size: 30px;
  }
  @media (max-width: 310px) {
    font-size: 27px;
    line-height: 32px;
  }
}

.text {
  font: 17px/29px var(--visby);
  color: #adadad;
  max-width: 520px;
  margin-top: 10px;
  padding-bottom: 20px;
  @media (max-width: 920px) {
    margin-top: 0px;
    max-width: unset;
  }
}

.subTitle {
  font: 30px/58px var(--visby-medium);
  color: #ffffff;
  grid-column: 1/3;
  grid-row: 2;
  align-self: flex-end;
  max-width: 520px;
  @media (max-width: 920px) {
    grid-row: 3;
  }
  @media (max-width: 550px) {
    font-size: 26px;
  }
  @media (max-width: 430px) {
    font-size: 24px;
  }
  @media (max-width: 317px) {
    line-height: 30px;
    margin-bottom: 16px;
  }
}

.wrapper {
  display: grid;
  grid-template-rows: 190px 62px;
  column-gap: 38px;
  margin-bottom: 10px;
  @media (max-width: 985px) {
    grid-template-rows: 230px 62px;
  }
  @media (max-width: 920px) {
    grid-template-rows: auto 198px 72px;
    grid-template-columns: 1fr;
    row-gap: 20px;
    column-gap: 0px;
  }
}

.adjustedWrapper {
  grid-template-columns: 527px 368px;
  grid-template-rows: 203px;
  column-gap: 32px;
  @media (max-width: 1072px) {
    grid-template-columns: auto 368px;
  }
  @media (max-width: 920px) {
    grid-template-columns: auto;
    grid-template-rows: 203px auto;
  }
  margin-bottom: 22px;
}

.adjustedWrapperMobile {
  margin-bottom: 0;
}

.homeAssignmentWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.oneHomeAssignmentWrapper {
  display: grid;
  grid-template-columns: 455px 455px;
  gap: 19px;
  @media (max-width: 1075px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 920px) {
    grid-template-columns: auto;
  }
}

.contentParagraph {
  font: 13px/21px var(--visby);
  color: #252342;
  p {
    padding-top: 0px;
    padding-bottom: 21px;
  }
}

.calendarScheduleWrapper {
  grid-column: 1/3;
  grid-row: 2;
}

.gameBannerWrapper {
  grid-column: 1/3;
  grid-row: 3;
}

.nextLessonTimerWrapper {
  max-width: 370px;
  height: 198px;
  grid-column: 2;
  grid-row: 1/3;
  @media (max-width: 920px) {
    grid-column: 1;
    grid-row: 2;
    background-color: #131314;
    width: 100%;
    max-width: unset;
    border-radius: 20px;
  }
}

.adjustedNextLessonTimerWrapper {
  @media (max-width: 920px) {
    grid-row: 1;
  }
}

.banner {
  grid-column: 1/3;
  grid-row: 4;
  @media (min-width: 1281px) {
    display: none;
  }
}
