.footerWrapper {
  font: 13px/21px var(--visby);
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  grid-column: 1/3;
  color: #ffffff;
  @media (max-width: 700px) {
    flex-direction: column;
    row-gap: 12px;
  }
  @media (max-width: 540px) {
    padding: 0 22px 32px 22px;
    margin-top: auto;
    align-self: flex-end;
  }
}

.footerText {
  padding-right: 85px;
  opacity: 0.4;
  max-width: 784px;
  @media (max-width: 700px) {
    padding-right: 0px;
    padding-left: 4px;
  }
}

.iconWrapper {
  width: 156px;
}
