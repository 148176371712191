.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: #131314;
  @media (max-width: 1060px) {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
  .labelWrapper {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .label {
    font: 21px/24px var(--visby-medium);
    color: #ffffff;
    width: 100%;
    padding: 22px 0 0 19px;
    margin-bottom: 0;
    @media (max-width: 310px) {
      font: 19px/21px var(--visby-medium);
    }
    @media (max-width: 300px) {
      padding: 22px 0 0 10px;
    }
  }
  .countdown {
    display: flex;
    padding: 17px 43px 39px 43px;
    @media (max-width: 420px) {
      padding: 17px 15px 39px 15px;
    }
  }
  .loading {
    padding-left: 12px;
    padding-right: 12px;
  }
  .buttonWrapper {
    display: flex;
    height: 100%;
    padding: 25px 0px 38px 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 50%;
    justify-content: center;
    @media (max-width: 400px) {
      width: 100%;
      padding: 25px 15px 38px 15px;
    }
    button > span {
      font-size: 16px;
    }
  }
  .time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .num {
    font: 52px var(--visby-medium);
    color: #ffffff;
    @media (max-width: 330px) {
      font: 40px var(--visby-medium);
    }
  }
  .dateText {
    font: 13px/20px var(--visby-semiBold);
    color: #ffffff;
    text-transform: uppercase;
  }
  .divider {
    &:global(.ant-divider-vertical) {
      border-left: 0.5px solid #ffffff;
      margin-top: 10px;
      height: 85px;
      margin: 16px 20px 0 28px;
      @media (max-width: 380px) {
        margin: 16px 10px 0 18px;
      }
      @media (max-width: 295px) {
        margin: 16px 7px 0 15px;
      }
    }
  }
  .separator {
    font: 32px/48px var(--visby-bold);
    color: #ffffff;
    max-height: 34px;
    margin: 14px 14px 12px 19px;
    @media (max-width: 380px) {
      margin: 14px 8px 12px 13px;
    }
    @media (max-width: 295px) {
      margin: 14px 6px 12px 11px;
    }
  }
}
