.title {
  color: #00fca3;
  font: 52px/60px var(--visby);
  margin-bottom: 30px;
  margin-top: 30px;
}

.desc {
  color: #ffffff;
  font: 13px/21px var(--visby);
  margin-bottom: 30px;
}

.buttonWrapper {
  width: 165px;
  button > span {
    font-size: 13px;
  }
}

.activeButtonWrapper {
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.greenTag {
  color: #000000;
  background: #00fca3;
  border-color: #00fca3;
  border-radius: 20px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  font: 13px/21px var(--visby-semiBold);
}

.redTag {
  color: #ffffff;
  background: #ec4339;
  border-color: #ec4339;
  border-radius: 20px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  font: 13px/21px var(--visby-semiBold);
}

.greyTag {
  color: #ffffff;
  background: #292f37;
  border-color: #292f37;
  border-radius: 20px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  font: 13px/21px var(--visby-semiBold);
}

.notificationBannerWrapper {
  margin-bottom: 20px;
}

.tableWrapper {
  @media (max-width: 930px) {
    display: none;
  }
}

.cardsWrapper {
  @media (min-width: 931px) {
    display: none;
  }
  gap: 24px;
  display: grid;
  grid-template-columns: auto auto;
  @media (max-width: 750px) {
    gap: 14px;
  }
  @media (max-width: 705px) {
    grid-template-columns: 1fr;
  }
}
