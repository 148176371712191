.container {
  width: 250px;
  border-radius: 20px;
  background-color: #00fca3;
  cursor: pointer;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 10px 20px rgba(#fff, 0.2);
  }
  @media (max-width: 640px) {
    max-width: unset;
    width: 100%;
  }
}

.titleWrapper {
  border-radius: 20px 20px 0 0;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 104px;
  display: flex;
  align-items: center;
}

.title {
  font: 21px/28px var(--visby);
  color: #131314;
}

.textWrapper {
  background-color: #ffff;
  border-radius: 20px;
}

.text {
  font: 13px/21px var(--visby);
  font-weight: 400;
  color: #131314;
  padding: 14px 20px 11px 20px;
  @media (max-width: 640px) {
    width: 100%;
    max-width: unset;
    height: 109px;
  }
}
