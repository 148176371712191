.wrapper {
  max-width: 1016px;
  @media (max-width: 1280px) {
    width: unset;
    max-width: 1010px;
    margin-top: 46px;
  }
}

.wrapperTitle {
  max-width: 700px;
}

.title,
.titleHighlight {
  font: 52px/58px var(--visby-medium);
  color: #ffffff;
  margin-bottom: 23px;

  @media (max-width: 768px) {
    font: 42px/38px var(--visby-medium);
    margin-bottom: 18px;
    margin-top: 30px;
  }

  @media (max-width: 570px) {
    font: 32px/31px var(--visby-medium);
    margin-bottom: 18px;
    margin-top: 30px;
  }

  @media (max-width: 440px) {
    margin-top: 30px;
  }

  @media (max-width: 320px) {
    margin-bottom: 18px;
  }
}

.subTitle {
  font: 20px/28px var(--visby);
  color: #ADADAD;
  max-width: 1200px;
  margin-bottom: 33px;
  @media (max-width: 1020px) {
    max-width: 650px;
  }
  @media (max-width: 990px) {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 28px;
  }
}
