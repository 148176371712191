.wrapper {
  max-width: 521px;
  background: #131314;
  border-radius: 20px;
  padding: 27px 20px 30px 21px;
  margin-top: 24px;
  @media screen and (max-width: 768px) {
    padding: 30px 20px;
  }
}

.title {
  margin-bottom: 20px;
  font: 20px/28px var(--visby);
  color: #ffffff;
}

.subTitle {
  font: 13px var(--visby);
  color: #ffffff;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.btnWrapper {
  height: 42px;
  width: 165px;
  margin: 0 0 16px 0;
  @media (max-width: 360px) {
    width: 100%;
  }
  button {
    span {
      font: 13px var(--visby-bold);
    }
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.description {
  font: 13px var(--visby);
  font-style: italic;
  color: #ADADAD;
}
