.container {
  max-width: 980px;
  margin-top: 40px;
  @media (max-width: 1084px) {
    margin-top: 0px;
  }
}

.filterWrapper {
  display: none;
  gap: 51px;
}

.filterDate,
.filterAccpeted {
  color: #ffffff;
  font: 15px var(--visby-medium);
  text-transform: capitalize;
}

.cardsWrapper {
  margin-top: 19px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cardWrapper {
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 450px;
  min-height: 221px;
  @media (max-width: 400px) {
    height: unset;
  }
}

.upperPart {
  min-height: 153px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: red;
  display: flex;
  flex-wrap: wrap;
  padding: 16px 26px 20px 25px;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 400px) {
    gap: 20px;
    height: unset;
    padding: 24px 26px 10px 25px;
  }
}

.downPart {
  height: 68px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: yellow;
  padding: 0 26px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date,
.status,
.company {
  color: #ffffff;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-self: center;
  font: 17px/17px var(--visby-medium);
  text-transform: capitalize;
  gap: 6px;
}

.label {
  font: 13px/21px var(--visby);
}

.desc {
  color: #ffffff;
  font: 20px/28px var(--visby);
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.socialWrapper {
  display: flex;
  align-items: center;
  gap: 33px;
}

.notification {
  position: relative;
  width: 31px;
  height: 24px;
}

.pointer {
  cursor: pointer;
}

.notificationMessageCount {
  position: absolute;
  font: 10px/10px var(--visby-medium);
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background-color: #ec4339;
  color: #ffffff;
  position: relative;
}

.count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.countNotifications {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.notificationMessageCount {
  position: absolute;
  top: -6px;
  right: -7px;
}

.addComment {
  width: 175px;
  height: 40px;
  color: #00fca3;
  font: 10px var(--visby-bold);
  padding: 6px 27px 6px 27px;
  border: 1px solid #232425;
  border-radius: 60px;
  cursor: pointer;
  background-color: #232425;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(#000, 0.2);
  }

  &:active {
    box-shadow: 0 5px 10px rgba(#000, 0.15);
    transform: translateY(0);
  }
}

.addCommentBlackCard {
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(#fff, 0.05);
  }

  &:active {
    box-shadow: 0 5px 10px rgba(#fff, 0.15);
    transform: translateY(0);
  }
}

.cardBlack {
  background-color: #141516;
}

.cardBlue {
  background-color: #2e466a;
}
.cardGreen {
  background-color: #155844;
}

.cardBlackish {
  background-color: #0a0a0a;
}
.cardBlueish {
  background-color: #032558;
}
.cardGreenish {
  background-color: #00fca3cc;
}
