.wrapper {
  display: flex;
  max-width: 970px;
  gap: 106px;
  align-items: center;
  @media (max-width: 1060px) {
    flex-direction: column;
    gap: 24px;
  }
}

.leftSideWrapper {
  max-width: 410px;
  @media (max-width: 1060px) {
    max-width: unset;
  }
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.title {
  font: 42px/46px var(--visby-bold);
  color: #ffffff;
  margin-bottom: 41px;
  margin-top: 20px;
  @media (max-width: 1060px) {
    margin-top: 24px;
    font-size: 32px;
    margin-bottom: 24px;
  }
}

.description {
  font: 15px/23px var(--visby);
  margin-bottom: 20px;
  color: #ADADAD;
}

.highlight {
  font: 15px/23px var(--visby-bold);
  margin-bottom: 20px;
  margin-top: 42px;
  color: #ffffff;
}

.rightSideWrapper {
  border-radius: 10px;
  padding: 25px 30px 0 40px;
  @media (max-width: 1060px) {
    max-width: unset;
    padding: 0;
  }
}

.sad {
  width: 353px;
  height: 353px;
  @media (max-width: 420px) {
    width: 300px;
    height: 300px;
  }
}
