.wrapper {
  width: 929px;
  min-height: 118px;
  background-color: #141415;
  border-radius: 20px;
  padding: 21px 30px 33px 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 10px;
  @media (max-width: 1075px) {
    width: unset;
    max-width: 929px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 15px;
  }
}

.title {
  color: #ffffff;
  font: 19px var(--visby-semiBold);
  margin-bottom: 35px;
}

.emailWrapper {
  max-width: 513px;
  @media (max-width: 1075px) {
    max-width: unset;
    width: 100%;
  }
}

.email {
  font: 26px/45px var(--visby);
  color: #ADADAD;
  height: 40px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.buttonWrapper {
  width: 165px;
  button span {
    font: 13px var(--visby-extraBold);
  }
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.buttonContainer {
  display: flex;
  gap: 8px;
  @media (max-width: 1075px) {
    width: 100%;
    justify-content: flex-end;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-end;
  }
}

.titleAndEmail {
  margin-bottom: 0px;
}
