.wrapper {
  height: 66px;
  background-color: #00fca3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.verifyEmailWrapper {
  width: 396px;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: 660px) {
    width: 100%;
  }
  @media (max-width: 365px) {
    flex-direction: column;
  }
}

.mailIcon {
  position: absolute;
  right: 45px;
  top: -19px;

  path {
    fill: rgba(0, 0, 0, 0.3);
  }
}
.description {
  display: flex;
  align-self: center;
  font: 12px var(--visby-semiBold);
  color: #000;
}
.verifyButton {
  z-index: 5;
  color: #00fca3;
  text-transform: uppercase;
  font: 9px var(--visby-bold);
  padding: 6px 12px;
  background-color: #232425;
  border-radius: 7px;
  cursor: pointer;

  @media (max-width: 365px) {
    margin-top: 8px;
  }
}
