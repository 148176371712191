.container {
  width: 1016px;
  @media (max-width: 1280px) {
    width: unset;
    max-width: 1010px;
    margin-top: 46px;
  }
}

.cardsWrapper {
  margin-top: 90px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  @media (max-width: 990px) {
    margin-top: 50px;
  }
}

.wrapperTitle {
  max-width: 700px;
}

.title,
.titleHighlight {
  font: 52px/58px var(--visby-medium);
  color: #ffffff;
  margin-bottom: 23px;

  @media (max-width: 768px) {
    font: 42px/38px var(--visby-medium);
    margin-bottom: 18px;
    margin-top: 30px;
  }

  @media (max-width: 570px) {
    font: 32px/31px var(--visby-bold);
    margin-bottom: 18px;
    margin-top: 30px;
  }

  @media (max-width: 440px) {
    margin-top: 30px;
  }

  @media (max-width: 320px) {
    margin-bottom: 18px;
  }
}

.titleHighlight {
  color: #00fca3;
}

.subTitle {
  font: 20px/28px var(--visby);
  color: #ADADAD;
  max-width: 650px;
  margin-bottom: 33px;

  @media (max-width: 1035px) {
    max-width: 600px;
  }

  @media (max-width: 990px) {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    margin-bottom: 28px;
    font: 20px var(--visby-medium);
  }
  @media (max-width: 600px) {
    margin-bottom: 46px;
  }
  @media (max-width: 600px) {
    font: 16px var(--visby-medium);
    margin-bottom: 36px;
  }
}

.wrapperSelectButtons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.button {
  width: 200px;
  padding: 6px 39px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #fff;
  border-radius: 20px;
  font: 24px/27px var(--visby);
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(#fff, 0.2);
  }

  &:active {
    box-shadow: 0 5px 10px rgba(#fff, 0.15);
    transform: translateY(0);
  }
}

.buttonSelected {
  background-color: #00fca3;
  color: #1c1f23;
  border: 1px solid #00fca3;
  font: 24px var(--visby-bold);
}
