.wrapper {
  background: #1c1f23;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapperHeader {
  margin-top: 5px;
  margin-bottom: 102px;
  background-image: linear-gradient(
    180deg,
    #363c46 0%,
    rgba(41, 45, 52, 0) 100%
  );

  @media (max-width: 768px) {
    margin-bottom: 32hpx;
  }
}

.center {
  display: flex;
  align-self: center;
}

.capWrapper {
  @media (min-width: 1499px) {
    display: flex;
    justify-content: center;
    justify-items: center;
    justify-self: center;
  }
}

.largeScreenCap {
  @media (min-width: 1499px) {
    width: 1170px;
  }
}
