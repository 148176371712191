.container {
  border-radius: 20px;
  background-color: #131314;
  width: 369px;
  height: 100%;
  padding: 21px 58px 31px 19px;
  @media (max-width: 1008px) {
    width: 100%;
  }
  @media (max-width: 400px) {
    padding: 21px 19px 31px 19px;
  }
}

.titlteWrapper {
  width: 290px;
  @media (max-width: 1008px) {
    width: 100%;
  }
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  color: #FFFFFF;
  font: 20px/28px var(--visby-medium);
}

.btnWrapper {
  width: 165px;
  margin-top: 17px;
  button {
    span {
      font: 13px var(--visby-bold);
    }
  }
  @media (max-width: 1008px) {
    margin-top: 37px;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}
