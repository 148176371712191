.title {
  margin-top: 32px;
  padding-bottom: 36px;
  font: 32px var(--visby-semiBold);
  color: #ffffff;
  max-width: 700px;
  @media (max-width: 768px) {
    font: 26px/32px var(--visby-semiBold);
  }
}

.description {
  margin-top: 32px;
  font: 14px/22px var(--visby-bold);
  color: #ffffff;

  @media (max-width: 400px) {
    font-size: 11px;
    line-height: 18px;
  }
}

.container {
  width: 600px;
  padding-bottom: 24px;

  @media (max-width: 700px) {
    width: 100%;
  }
}

.buttonWrapper {
  margin-top: 24px;
  max-width: 400px;

  @media (max-width: 1000px) {
    margin: 24px auto;
    overflow: hidden;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
}

.checkDescription {
  font: 14px/16px var(--visby-bold);
  color: #ffffff;
  @media (max-width: 400px) {
    font-size: 11px;
    line-height: 18px;
  }
}
