.step {
  margin-bottom: 0;
  font: 11px/17px var(--visby);
  color: #ffffff;
  background: transparent;
  padding: 0px 26px 0px 0px;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  display: grid;
  grid-template-columns: 18px 230px;
  grid-template-rows: 33px 17px;
  column-gap: 13px;
}

.circleSmall {
  z-index: 99999;
  display: grid;
  justify-items: center;
  align-items: flex-end;
  grid-column: 1;
  grid-row: 2;
}
.stepActive {
  color: #00fca3;
  .circleWrapper {
    width: 16px;
    height: 16px;
    border-radius: 55%;
    circle {
      stroke: #00fca3;
      box-shadow: 0px 0px 13px #00fca3;
    }
    svg {
      border-radius: 55%;
      box-shadow: 0px 0px 13px #00fca3;
    }
  }
  .lineWrapper {
    rect {
      fill: #00fca3;
    }
  }
  .circleSmall {
    path {
      stroke: #00fca3;
    }
    svg {
      border-radius: 55%;
      box-shadow: 0px 0px 13px #00fca3;
    }
  }
}

.stepCompleted {
  color: #00fca3;
  .circleWrapper {
    width: 16px;
    height: 16px;
    border-radius: 55%;
    circle {
      stroke: #00fca3;
      fill: #1c1f23;
    }
    path {
      fill: #00fca3;
    }
    svg {
      border-radius: 55%;
      box-shadow: none;
    }
  }
  .lineWrapper {
    rect {
      fill: #00fca3;
    }
  }
  .circleSmall {
    path {
      stroke: #00fca3;
    }
    svg {
      border-radius: 55%;
      box-shadow: 0px 0px 13px #00fca3;
    }
  }
}

.circleWrapper {
  z-index: 99999;
  display: grid;
  justify-items: center;
  align-items: flex-end;
  grid-column: 1;
  grid-row: 2;
}

.lineWrapper {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
}
.titleWrapper {
  grid-column: 2;
  grid-row: 2;
}

.wrapper {
  margin-top: 100px;
  margin: 100px 0 0 32px;
  background: #1c1f23;
  height: 100vh;
  width: calc(100% - 64px);
  padding: 33px 73px 0px 41px;
  font: 14px/21px var(--visby-semiBold);
  color: #ffffff;
  transform: translate(-120%);
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: 0px;
  top: 44px;
  overflow: auto;
  z-index: 89999;
  a:not(:last-child) {
    padding: 0 0 26px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    text-transform: capitalize;
  }
  a:hover {
    color: #00fca3;
  }

  @media screen and (max-width: 600px) {
    padding-left: 26px;
  }

  @media (max-width: 540px) {
    top: 48px;
    padding-right: 41px;
    margin: 66px 0 0 0;
    width: 100%;
  }
}
.removeSpace {
  top: 0px;
}

.open {
  transform: unset;
  transition: transform 0.3s ease-in-out;
}
