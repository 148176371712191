.wrapper {
  width: 100%;
  max-width: 960px;
  padding-top: 26px;
}

.approvedWrapper {
  display: flex;
  gap: 39px;
  @media (max-width: 1075px) {
    flex-direction: column;
  }
}

.rejectedWrapper {
  display: flex;
  gap: 39px;
  @media (max-width: 1075px) {
    flex-direction: column;
  }
}

.leftPartApprove {
  overflow: hidden;
  display: flex;
  padding: 0px 0 0px 18px;
  background: #00fca3;
  width: 521px;
  border-radius: 20px;
  color: #252342;
  @media (max-width: 665px) {
    width: 100%;
    max-width: 521px;
  }
}

.textWrapper {
  max-width: 335px;
  margin-top: 23px;
  padding-bottom: 30px;
}

.infoBannerWrapper {
  width: 370px;
  @media (max-width: 1075px) {
    width: unset;
    max-width: 521px;
    div:first-child {
      width: 100%;
    }
  }
}

.leftPartReject {
  padding: 36px 35px 58px;
  background: #ec4339;
  width: 100%;
  max-width: 521px;
  border-radius: 20px;
  position: relative;
  color: #ffffff;
}

.rightPartReject {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: #131314;
  width: 370px;
  border-radius: 20px;
  padding: 32px 39px 32px 39px;
  @media (max-width: 1075px) {
    width: 100%;
    max-width: 521px;
  }
}

.pendingWrapper {
  display: flex;
  gap: 39px;
  @media (max-width: 1075px) {
    flex-direction: column;
  }
}

.leftPartPending {
  padding: 36px 35px 58px;
  background: #00fca3;
  width: 521px;
  border-radius: 20px;
  position: relative;
  color: #252342;
  @media (max-width: 1075px) {
    width: 100%;
    max-width: 521px;
  }
}

.rightPartPending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: #131314;
  border-radius: 20px;
  min-height: 194px;
  width: 370px;
  @media (max-width: 1075px) {
    width: 100%;
    max-width: 521px;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
}

.descriptionReject {
  max-width: 347px;
  font: 16px/24px var(--visby);
  color: #00fca3;
}

.descriptionReapply {
  font: 22px var(--visby);
  color: #00fca3;
  margin: 12px auto;
}

.descriptionPending {
  font: 22px var(--visby);
  color: #00fca3;
  margin: 12px auto;
}

.stampIconWrapper {
  position: relative;

  width: 223px;
  height: 203px;
  top: -22px;
  left: -15px;
  svg {
    position: absolute;
    width: 223px;
    height: 203px;
  }
}

.whatCanDo {
  font: 20px/28px var(--visby-semiBold);
  color: #0a2e36;
  margin-bottom: 18px;
}

.time {
  font: 52px/78px var(--visby-semiBold);
  margin-top: 12px;
}

.label {
  font: 20px/28px var(--visby);
  margin-bottom: 0;
}

.statusApprove {
  display: inline-block;
  margin-bottom: 5px;
  color: #0a2e36;
  font: 20px/28px var(--visby-semiBold);
}

.statusAnother {
  font: 20px/28px var(--visby-semiBold);
  margin-bottom: 5px;
  color: #ffffff;
  display: inline-block;
}
.description {
  font: 13px/21px var(--visby);
  max-width: 720px;
  margin-bottom: 16px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttonWrapper {
  width: 195px;
  height: 42px;
  @media (max-width: 450px) {
    width: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  button > span {
    font-size: 13px;
  }
  a > span {
    font-size: 13px;
  }
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#000, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(#000, 0.15);
      transform: translateY(0);
    }
  }
  a {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#000, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(#000, 0.15);
      transform: translateY(0);
    }
  }
}

.approvedTitle {
  font: 20px/28px var(--visby-semiBold);
  color: #0a2e36;
  margin-bottom: 18px;
  @media (max-width: 768px) {
    color: #476268;
    font: 14px/22px var(--visby-semiBold);
  }
}

.content {
  margin-bottom: 18px;
  h4 {
    font: 20px/28px var(--visby);
    font: 600;
    color: #252342;
  }

  h1 {
    font: 20px/28px var(--visby-semiBold);
    color: #252342;
  }
  li,
  p {
    list-style-type: decimal;
    font: 16px/26px var(--visby);
    font: 400;
    a {
      color: #252342;
      text-decoration: underline;
    }
  }
  li {
    margin-left: 26px;
  }
}
