.wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  margin-bottom: 100px;
  flex-wrap: wrap;
  @media (max-width: 920px) {
    justify-content: start;
  }
  @media (max-width: 660px) {
    flex-direction: column;
  }
}

.wrapperForTwoMonths {
  justify-content: flex-start;
}

.lessons {
  display: flex;
  flex-direction: column;
}

.titleMonth {
  color: white;
  font: 30px/58px var(--visby-medium);
  margin-top: 56px;
  margin-bottom: 11px;
  @media (max-width: 660px) {
    margin-top: 35px;
  }
}
