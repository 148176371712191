.nextWrapper {
  background: #ffffff;
  border-radius: 20px;
  padding: 36px 35px;
}

.statusWrapper {
  position: relative;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.approvedWrapper {
  display: grid;
  grid-template-columns: 2fr 1.25fr;
  grid-gap: 0;
  border-radius: 20px;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.description {
  font: 16px var(--visby);
  color: #0a2e36;
  max-width: 710px;
  margin-bottom: 18px;
}

.title {
  font: 28px var(--visby-semiBold);
  color: #252342;
  margin-bottom: 18px;

  @media (max-width: 490px) {
    font: 18px var(--visby-semiBold);
  }
  @media (max-width: 360px) {
    font: 16px var(--visby-semiBold);
  }
}
