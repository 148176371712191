.wrapper {
  background-color: #131314;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 11px;
  padding-top: 10px;
  padding-left: 41px;
  border-radius: 20px 0 0 20px;
  margin-bottom: 50px;
}

.link {
  margin-top: 10px;
  a {
    margin-bottom: 0;
    font: 13px/20px var(--visby);
    color: #ffffff;
    background: transparent;
    padding: 6px 26px 7px 26px;
    border-radius: 30px;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #1c1f23;
      color: #00fca3;
      font: 13px/20px var(--visby-semiBold);
    }
  }
}

.mainLinkActive {
  div:first-child {
    a {
      color: #00fca3;
      font: 13px var(--visby-semiBold);
      &:hover {
        color: #00fca3;
      }
    }
  }
}

.activelink {
  div:first-child {
    a {
      background: #1c1f23;
      color: #00fca3;
      font: 13px/20px var(--visby-semiBold);
      &:hover {
        background: #1c1f23;
        color: #00fca3;
      }
    }
  }
}

.linkWithSublinks {
  background: #1c1f23;
  color: #00fca3;
  border-radius: 10px;
  margin-right: 35px;
  padding: 10px 0 18px 0;
  .subLinks:nth-child(2) {
    margin-top: 10px;
  }
}

.subLinks {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  a {
    color: #ffffff;
  }
}

.subLinkActive {
  a {
    color: #00fca3;
    font: 13px var(--visby-semiBold);
  }
}

.banner {
  width: 228px;
  margin-top: 69px;
  margin-left: -20px;
}
