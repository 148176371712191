.steps {
  & :global(.ant-timeline) {
    display: grid;
  }

  & :global(.ant-timeline-item) {
    padding-bottom: 100px;
    &:last-child {
      padding-bottom: 0;
    }
  }

  & :global(.ant-timeline-item-head-custom) {
    padding: unset;
    background: none;
  }

  & :global(.ant-timeline .ant-timeline-item .ant-timeline-item-tail) {
    top: 23px;
    height: calc(100% - 34px);
    border-left: 2.5px solid #00fca3;
  }

  @media (max-width: 768px) {
    & :global(.ant-timeline-item) {
      padding-bottom: 20px;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.dot {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: #00fca3;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 10px var(--visby-bold);
  color: #252342;
  padding-top: 2px;
}

.dotWaiting {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: #00fca3;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 10px var(--visby-bold);
  color: #252342;
  opacity: 0.8;
}

.stepsSecondary {
  & :global(.ant-timeline-item) {
    padding-bottom: 26px;
    &:last-child {
      padding-bottom: 0;
    }
  }
}
