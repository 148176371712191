.container {
  width: 250px;
  height: 213px;
  border-radius: 20px;
  background-color: #00fca3;
  cursor: pointer;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 10px 20px rgba(#fff, 0.2);
  }
  @media (max-width: 640px) {
    max-width: unset;
    width: 100%;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 125px);
  @media (max-width: 640px) {
    grid-template-columns: auto minmax(213px, 1fr);
  }
}

.titleWrapper {
  width: 168px;
  border-radius: 20px 20px 0 0;
  grid-column: 1/3;
  grid-row: 1;
}

.title {
  font: 21px/28px var(--visby);
  padding: 41px 0 35px 21px;
  color: #131314;
}

.textWrapper {
  background-color: #ffff;
  border-radius: 20px;
  grid-column: 1/3;
  grid-row: 2;
}

.text {
  color: #131314;
  font: 13px/21px var(--visby);
  padding: 14px 15px 11px 20px;
  @media (max-width: 640px) {
    width: 100%;
    max-width: unset;
    height: 109px;
  }
}

.icon {
  width: 40px;
  grid-column: 2/3;
  grid-row: 1;
  align-self: center;
  justify-self: end;
  margin-right: 30px;
}
