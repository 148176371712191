.wrapper {
  display: grid;
  grid-template-columns: 460px 238px;
  column-gap: 70px;
  @media (max-width: 915px) {
    column-gap: 30px;
  }
  @media (max-width: 875px) {
    grid-template-columns: auto 238px;
  }
  @media (max-width: 760px) {
    grid-template-columns: auto;
    grid-template-rows: 238px auto;
    row-gap: 30px;
  }
  @media (max-width: 345px) {
    grid-template-rows: 190px auto;
  }
}

.textWrapper {
  @media (max-width: 760px) {
    grid-row: 2;
  }
}

.title {
  font: 52px/46px var(--visby-medium);
  color: #ffffff;
  margin-bottom: 15px;
  @media (max-width: 370px) {
    font-size: 48px;
  }
  @media (max-width: 345px) {
    font-size: 42px;
  }
}

.subTitle {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
  margin-bottom: 18px;
}

.text {
  max-width: 410px;
  font: 13px/21px var(--visby);
  margin-bottom: 9px;
  color: #ADADAD;
  @media (max-width: 760px) {
    max-width: 550px;
    padding-right: 25px;
  }
}

.imageWrapper {
  border-radius: 10px;
  padding: 25px 0px 0 0;
  @media (max-width: 760px) {
    padding: 0;
    grid-row: 1;
  }
  @media (max-width: 345px) {
    width: 190px;
  }
}

.highFiveIcon {
  width: 100%;
  height: auto;
}

.buttonWrapper {
  display: flex;
  gap: 20px;
  @media (max-width: 440px) {
    flex-direction: column;
  }
  button {
    max-width: 190px;
    font-size: 13px !important;
    @media (max-width: 770px) {
      max-width: unset;
    }
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.requirements {
  font-family: var(--visby-semiBold);
}
