.wrapper {
  padding-top: 42px;
  position: relative;
  width: 600px;
  height: 520px;
  @media (max-width: 650px) {
    width: 100%;
  }
}

.envelopeWrapper {
  width: 65px;
  height: 65px;
  position: absolute;
  background-color: #00fca3;
  border-radius: 100px;
  z-index: 4;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardWrapper {
  padding: 55px 22px 27px 22px;
  border-radius: 22px;
  background-color: #ffffff;
  width: 600px;
  height: 520px;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.descWrapper {
  display: flex;
}

.cardDesc {
  margin-top: 46px;
  font: 17px/28px var(--visby-medium);
  color: hsla(244, 31%, 20%, 1);
}

.envelopeIcon {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 31px;
  height: 24px;
  path {
    fill: #252342;
  }
}

.buttonWrapper {
  width: 194px;
  display: flex;
  justify-self: center;
  justify-content: center;
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#000, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#000, 0.15);
      transform: translateY(0);
    }
    span {
      font: 13px/21px var(--visby-bold);
    }
  }
}

.strong {
  font-family: var(--visby-bold);
}

.close {
  position: absolute;
  top: 70px;
  right: 32px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
