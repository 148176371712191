.wrapper {
  display: flex;
  gap: 20px;
  height: 267px;
  position: relative;
  @media (max-width: 1020px) {
    flex-direction: column;
    height: unset;
  }
}

.video {
  width: 455px;
  height: 267px;
  flex-shrink: 0;
  @media (max-width: 1020px) {
    max-width: 800px;
    width: 100%;
  }
}

.listWrapper {
  width: 450px;
  @media (max-width: 558px) {
    width: 100%;
  }
}

.actionWrapper {
  width: 100%;
  max-width: 470px;

  @media (max-width: 558px) {
    max-width: unset;
    width: 100%;
  }
}

.title {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
  margin-bottom: 5px;
}

.buyNowButtonWrapper {
  margin-top: 26px;
  width: 165px;
  transition: all 0.2s;
  button {
    span {
      font: 13px var(--visby-semiBold);
    }
  }

  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
  @media (max-width: 460px) {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    position: unset;
  }
}
