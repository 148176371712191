.wrapper {
  display: flex;
  position: relative;

  & :not(:last-child) {
    margin-right: 10px;
    @media (max-width: 440px) {
      margin-right: 22px;
    }

    @media (max-width: 410px) {
      margin-right: 16px;
    }

    @media (max-width: 380px) {
      margin-right: 12px;
    }

    @media (max-width: 360px) {
      margin-right: 6px;
    }
  }
}

.referralLogo {
  position: absolute;
  top: -25px;
  right: 50px;
  width: 222px;
  height: 222px;
  svg {
    filter: drop-shadow(0 0 32px #00fca3);
  }

  @media (max-width: 1279px) {
    width: 200px;
    height: auto;
    top: 37px;
    right: 40px;
  }
  @media (max-width: 990px) {
    display: none;
  }
}
