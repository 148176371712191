.wrapper {
  margin-top: 42px;
  position: relative;
  width: 600px;

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }
}

.cardWrapper {
  padding: 55px 22px 0px 22px;
  border-radius: 22px;
  background-color: #1c1f23;
  width: 600px;

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }
}

.descWrapper {
  display: flex;
}

.cardDesc,
.startDateDesc {
  margin-top: 46px;
  font: 14px/28px var(--visby);
  color: #ffffff;
  opacity: 0.8;
}

.startDateDesc {
  margin-top: 0;
}

.offerLabel {
  font: 14px/28px var(--visby);
  color: #ffffff;
  opacity: 0.8;
}

.portfolioWrapper {
  width: 66px;
  height: 66px;
  position: absolute;
  background-color: #00fca3;
  border-radius: 100px;
  z-index: 4;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.portfolioIcon {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37px;
  height: 32.11px;
  path {
    fill: #252342;
  }
}

.buttonWrapper {
  width: 194px;
  display: flex;
  justify-self: center;
  justify-content: center;
  margin-bottom: 22px;
  button > span {
    font: 13px var(--visby-bold);
  }
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.strong {
  font-family: var(--visby-bold);
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #292f37;
  padding: 12px;
  border-radius: 50%;
}

.radioButton {
  background-color: #292f37;
  color: rgba(255, 255, 255, 0.7);
  font: 14px var(--visby-medium);
  padding: 9px 31px;
  border-radius: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.buttonActive {
  background-color: #00fca3;
  color: #000;
  font-family: var(--visby-bold);
}

.calendarIconWrapper {
  path {
    stroke: rgba(255, 255, 255, 0.7);
  }
}

.rejectOffer {
  path {
    stroke: #00000040;
  }
}

.startDateDesc {
  font: 14px var(--visby);
  color: #ffffff;
  opacity: 0.8;
}

.rejectOfferDesc {
  color: #ffffff;
}

.takeOfferItem {
  & [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
    border-color: transparent !important;
  }
  div {
    div {
      div {
        div {
          label {
            span {
              &::after {
                border: none;
              }
              &:hover {
                span &:after {
                  border: none;
                }
              }
              span {
                z-index: -2;
                width: 0;
                height: 0;
                border: none;
                animation: none;

                &::after {
                  display: none;
                  animation: none;
                }
                input {
                  cursor: initial;
                  display: none;
                  border: none;
                  cursor: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.takeOfferAnswerWrapper {
  margin-top: 12px;
  display: flex;
  label {
    span {
      span {
        margin-left: -8px;
      }
    }
  }
}
