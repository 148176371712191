.dateAndTimeBlock {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  &:last-child {
    margin-bottom: 0;
  }
}

.subText {
  font: 18px/22px var(--visby);
  color: #ffffff;
  margin: 0;
  @media (max-width: 768px) {
    font: 14px/17px var(--visby);
  }
}

.dateAndTime {
  font: 18px/22px var(--visby-bold);
  color: #ffffff;
  margin-left: 13px;
}

.buttonBlock {
  display: grid;
  align-items: center;
  grid-template-columns: 100px auto;
  @media (max-width: 768px) {
    grid-template-columns: 80px auto;
  }
}

.joinButton {
  grid-column: 2;
  grid-row: 1;
  margin-left: 13px;
  width: 225px;
  @media (max-width: 450px) {
    grid-column: 1/3;
    grid-row: 2;
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
  button > span {
    font-size: 13px;
  }
}

.linkDescription {
  margin-top: 22px;
  font: 14px/24px var(--visby);
  color: #ffffff;
  margin-bottom: 0;
}

.passcodeWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
}

.passcodeText {
  font: 16px/24px var(--visby);
  color: #ffffff;
  @media (max-width: 768px) {
    font: 12px/15px var(--visby);
  }
}

.passcode {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
  margin-left: 6px;
  @media (max-width: 768px) {
    font: 14px/22px var(--visby-bold);
  }
}

.descText {
  grid-column: 1/3;
  grid-row: 2;
  font: 13px/21px var(--visby);
  color: #ADADAD;
  margin-top: 5px;
  @media (max-width: 450px) {
    grid-row: 3;
  }
}
