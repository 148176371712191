.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    .label {
      color: #00fca3;
    }
    .icon {
      filter: grayscale(100%) brightness(1000%);
    }
  }
}

.label {
  font: 14px/19px var(--visby-semiBold);
  color: #ffffff;
  margin-right: 16px;
}

.icon {
  width: 20px;
  height: 20px;
}
