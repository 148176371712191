.container {
  width: 250px;
  border-radius: 20px;
  background-color: #00fca3;

}

.container,
.containerWithImage {
  @media (max-width: 640px) {
    max-width: unset;
    width: 100%;
  }
}

.containerWithImage {
  background-color: rgba(0, 252, 163, 0.8);
  @media (max-width: 640px) {
    background-color: #00fca3;
    background-image: none !important;
  }
}

.titleWrapper {
  border-radius: 20px 20px 0 0;
  grid-column: 1/3;
  grid-row: 1;
}

.title {
  font: 20px/15px var(--visby-medium);
  padding: 30px 0 18px 21px;
  color: #131314;
}

.titleWithImage {
  color: #ffff;
  @media (max-width: 640px) {
    color: #131314;
  }
}

.textWrapper {
  padding: 14px 20px 38px 20px;
  background-color: #ffff;
  border-radius: 20px;
  min-height: 164px;
  @media (max-width: 1008px) {
    min-height: 145px;
  }
  @media (max-width: 640px) {
    min-height: 100px;
  }
}

.text {
  width: 214px;
  padding-bottom: 10px;
  color: #131314;
  font: 13px/17px var(--visby);
  @media (max-width: 640px) {
    width: 100%;
    height: 53px;
  }
}
