.wrapper {
  display: grid;
  grid-template-columns: 400px 238px;
  grid-template-rows: 58px auto;
  column-gap: 80px;
  row-gap: 9px;
  margin-top: 50px;
  @media (max-width: 1280px) {
    margin-bottom: 50px;
  }
  @media (max-width: 865px) {
    grid-template-columns: auto 238px;
  }
  @media (max-width: 795px) {
    gap: 30px;
  }
  @media (max-width: 770px) {
    grid-template-columns: auto;
  }
}

.titleWrapper {
  @media (max-width: 770px) {
    grid-row: 1;
  }
}

.messageWrapper {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 770px) {
    grid-row: 3;
  }

  grid-column: 1;
  grid-row: 2;
}

.title {
  font: 52px/58px var(--visby-medium);
  margin-bottom: 10px;
  color: #00fca3;

  @media (max-width: 500px) {
    font-size: 46px;
  }

  @media (max-width: 350px) {
    font-size: 40px;
  }
}

.message {
  width: 100%;
  font: 13px/21px var(--visby);
  margin-top: 18px;

  @media (max-width: 770px) {
    margin-bottom: 30px;
  }
}

.highlightText {
  font: 20px/28px var(--visby-semiBold);
}

.imageWrapper {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  grid-row: 1/3;

  @media (max-width: 770px) {
    grid-row: 2;
  }

  @media (max-width: 350px) {
    width: 190px;
  }
}

.highFiveIcon {
  @media (max-width: 350px) {
    width: 100%;
    height: auto;
  }
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  gap: 20px;

  :global {
    .ant-btn {
      width: 165px;
      font-size: 13px;
      line-height: 21px;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 550px) {
    flex-direction: column;
  }

  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}
