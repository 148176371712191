.wrapper {
  margin-top: 66px;
  max-width: 700px;
  @media (max-width: 1279px) {
    margin-top: 22px;
    max-width: unset;
  }
}

.title,
.titleHighlight {
  font: 52px/58px var(--visby-medium);
  color: #ffffff;  
  margin-bottom: 23px;
  @media (max-width: 1279px) {
    max-width: 700px;
  }
  @media (max-width: 1100px) {
    max-width: 700px;
  }
  @media (max-width: 1050px) {
    max-width: 680px;
  }
  @media (max-width: 800px) {
    font: 42px/47px var(--visby-medium);
    margin-top: 30px;
  }
  @media (max-width: 730px) {
    max-width: 550px;
    font: 46px/52px var(--visby-medium);
    margin-top: 30px;
  }
  @media (max-width: 360px) {
    font: 32px/36px var(--visby-medium);
  }
}

.titleHighlight {
  color: #ffffff;
  text-justify: center;
}

.subTitle {
  font: 20px/28px var(--visby);
  color: #ADADAD;
  max-width: 620px;
  margin-bottom: 33px;
  @media (max-width: 1080px) {
    max-width: 600px;
  }
  @media (max-width: 1045px) {
    max-width: 550px;
  }
  @media (max-width: 990px) {
    max-width: 100%;
  }
}

.stepsBlock {
  margin-bottom: 26px;
}

.titleSteps {
  color: #ffffff;
  font: 30px var(--visby-medium);
  margin-bottom: 22px;
}

.stepsWrap {
  margin-left: 10px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.buttonWrapper {
  max-width: 165px;
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.button {
  font: 13px/21px var(--visby-bold);
}

.referralLogo {
  z-index: 2;
  position: absolute;
  top: -25px;
  right: 50px;
  width: 222px;
  height: 222px;
  svg {
    filter: drop-shadow(0 0 32px #00fca3);
  }
  @media (max-width: 1279px) {
    top: 37px;
    right: 40px;
  }
  @media (max-width: 990px) {
    display: none;
  }
}
