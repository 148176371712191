.container {
  background: #f9f2ff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px 19px 24px;
  position: relative;
  @media (min-width: 960px) and (max-width: 1280px) {
    display: grid;
    grid-template-columns: 560px auto;
  }
  @media (min-width: 730px) and (max-width: 960px) {
    display: grid;
    grid-template-columns: auto 199px;
    column-gap: 10px;
  }
}

.background {
  top: -10px !important;
  @media (min-width: 730px) and (max-width: 1280px) {
    transform: rotate(90deg) scale(3.7);
  }
  @media (max-width: 730px) {
    transform: scale(1.7);
  }
}

.logo {
  display: flex;
  justify-content: center;
  @media (min-width: 730px) and (max-width: 1280px) {
    justify-content: start;
    grid-row: 1;
    grid-column: 1;
  }
}

.title {
  font: 23px/22px var(--visby-bold);
  color: #351b50;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 22px;
  @media (min-width: 730px) and (max-width: 1280px) {
    grid-row: 2;
    grid-column: 1;
    text-align: start;
    margin-bottom: 10px;
  }
  @media (min-width: 600px) and (max-width: 731px) {
    max-width: 400px;
  }
}

.buttonWrapper {
  width: 100%;
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#000, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(#000, 0.15);
      transform: translateY(0);
    }
  }
  @media (min-width: 730px) and (max-width: 1280px) {
    grid-row: 4;
    grid-column: 1;
    width: 265px;
  }
  @media (min-width: 600px) and (max-width: 731px) {
    grid-row: 4;
    grid-column: 1;
    width: 265px;
  }
}

.text {
  font: 15px/17px var(--visby);
  margin-top: 12px;
  margin-bottom: 15px;
  color: #351b50;
  text-align: center;
  @media (min-width: 730px) and (max-width: 1280px) {
    grid-row: 3;
    grid-column: 1;
    text-align: start;
  }
  @media (max-width: 857px) {
    max-width: 280px;
  }
}

.logoContainer {
  display: flex;
  justify-items: center;
  align-items: center;
}

.logoWrapper {
  max-width: 104px;
  max-height: 102px;
  @media (min-width: 730px) and (max-width: 1280px) {
    grid-row: 1/5;
    grid-column: 2;
    justify-self: center;
    align-self: center;
    max-width: 202px;
    max-height: 199px;
  }
}
