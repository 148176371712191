.wrapper {
  margin-top: 97px;
  background: #1c1f23;
  height: 100vh;
  width: calc(100% - 64px);
  margin-left: 32px;
  padding: 33px 73px 0px 41px;
  font: 14px/21px var(--visby-semiBold);
  color: #ffffff;
  transform: translate(-120%);
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: 0px;
  top: 60px;
  overflow: auto;
  z-index: 20;
  a:not(:last-child) {
    padding: 0 0 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
  }
  .menuSubTitles {
    margin-left: 14px;
    font-size: 13px;
    padding-bottom: 20px !important;
  }
  a:hover {
    color: #00fca3;
  }
  @media screen and (max-width: 600px) {
    padding-left: 26px;
  }
  @media (max-width: 540px) {
    top: 26px;
    padding-right: 41px;
    margin-left: 0;
    width: 100%;
  }
}

.open {
  transform: unset;
  transition: transform 0.3s ease-in-out;
}

.menuItem {
  a {
    padding: 0 0 26px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
  }
}

.menuItemWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.active {
  color: #00fca3;
  transition: color 0.3s ease-in-out;
}

.menuItemCollapse {
  flex-direction: column;
  align-items: flex-start;
}

.menuItemArrow {
  transition: transform 0.3s ease;
}

.menuItemCollapseOpen .menuItemArrow {
  transform: rotate(180deg);
}

.menuSubItems {
  height: 0;
  overflow: hidden;
}

.collapseOpen {
  padding-left: 32px;
  margin-top: 20px;
  height: auto;
  overflow: visible;
}

.userProfile {
  display: none;
  margin-top: 29px;
  div {
    max-height: 120px;
  }

  @media (max-width: 1279px) {
    display: block;
  }
}

.signOut {
  display: none;
  div {
    max-height: 120px;
  }

  @media (max-width: 1279px) {
    display: block;
    margin-bottom: 20px;
  }
}

.emailVerified {
  margin-top: 166px;
}
