.wrapper {
  padding-top: 24px;
}

.title {
  font: 52px/78px var(--visby-semiBold);
  color: #ffffff;
  margin-bottom: 18px;

  @media (max-width: 768px) {
    font: 42px/52px var(--visby-semiBold);
  }
}

.subTitle {
  font: 20px/28px var(--visby-semiBold);
  color: #00fca3;
  margin-bottom: 24px;
}

.text {
  font: 13px/21px var(--visby);
  color: #ADADAD;
  margin-bottom: 10px;
}

.highlightText {
  font-style: italic;
}

.applicationBlock {
  margin-bottom: 32px;
  @media (max-width: 450px) {
    margin-bottom: 42px;
  }
}

.adminText {
  font: 13px/21px var(--visby);
  color: #ADADAD;
  padding-left: 11px;
}

.timeLineWrapper {
  padding-left: 5px;
  display: grid;
  gap: 25px;
  & :global(.ant-timeline) {
    display: grid;
    gap: 22px;
  }
  & :global(.ant-timeline-item) {
    padding-bottom: 25px;
  }
  & :global(.ant-timeline-item-head-custom) {
    padding: unset;
    background-color: #00fca3;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 10px var(--visby-bold);
  }
  & :global(.ant-timeline .ant-timeline-item .ant-timeline-item-tail) {
    top: 30px;
    height: calc(100% - 25px);
    border-left: 1px solid #00fca3;
  }
}
