.container {
  width: 369px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  background-color: #131314;
  padding: 21px 20px 31px 20px;
  @media (max-width: 1008px) {
    width: 519px;
    padding: 21px 20px 21px 20px;
  }
  @media (max-width: 640px) {
    max-width: unset;
    width: 100%;
  }
}

.title {
  color: #00fca3;
  font: 21px/28px var(--visby-medium);
  width: 207px;
  @media (max-width: 1008px) {
    width: 100%;
    margin-bottom: 50px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 380px) {
    margin-top: 35px;
  }
}

.btnWrapper {
  width: 165px;
  button {
    font: 13px var(--visby-bold);
  }
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.btnText {
  font-size: 13px;
}
.icon {
  svg {
    width: 75px;
    height: 53px;
  }
  path {
    fill: #00fca3;
  }
  @media (max-width: 1008px) {
    svg {
      width: 90px;
      height: 58px;
    }
  }
  @media (max-width: 540px) {
    svg {
      width: 75px;
      height: 53px;
    }
  }
  @media (max-width: 380px) {
    display: none;
  }
}
