.container {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  background-color: #131314;
  width: 520px;
  padding: 0 0 0 20px;
  @media (max-width: 640px) {
    max-width: unset;
    width: 100%;
  }
}

.wrapper {
  display: grid;
}

.title {
  color: #00fca3;
  font: 21px/28px var(--visby);
  margin: 21px 20px 11px 0;
  grid-column: 1;
  grid-row: 1;
}

.iconWrapper {
  position: absolute;
  width: 128px;
  right: -140px;
  bottom: -160px;
  filter: drop-shadow(0 0 74px #00fca3);
  width: 277px;
  @media (max-width: 452px) {
    display: none;
  }
}

.textWrapper {
  font: 13px/21px var(--visby);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 240px;
  grid-column: 1;
  grid-row: 2;
}

.text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.btnWrapper {
  width: 165px;
  margin: 63px 0 38px 0;
  grid-column: 1;
  grid-row: 3;
  button {
    span {
      font: 13px var(--visby-bold);
    }
  }
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
  @media (max-width: 411px) {
    margin: 35px 0 38px 0;
  }
}

.btnWrapperWithPaymentSchedule {
  margin-top: 42px;
}

.icon {
  margin-top: 1px;
  text-align: center;
  width: 13px;
  height: 13px;
  path {
    stroke: #00fca3;
  }
}
