.wrapper {
  width: 100%;
  background-color: #00fca3;
  color: #000000;
  font: 16px/26px var(--visby-semiBold);
  padding: 15px 30px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: auto 115px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  @media (max-width: 769px) {
    margin-bottom: 20px;
  }
  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.buttonWrapper {
  width: 112px;
  button {
    span {
      font: 13px var(--visby-bold);
    }
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#000, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(#000, 0.15);
      transform: translateY(0);
    }
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}

.text > p > span {
  background-color: #000000;
  color: #ffffff;
  padding: 2px 5px;
  border-radius: 5px;
}
