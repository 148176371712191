.wrapper {
  display: flex;
  max-width: 1010px;
  gap: 40px;
  flex-direction: column;
  align-items: right;
  min-height: 400px;
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
  :global {
    .ant-btn {
      font-size: 13px;
      line-height: 21px;
    }
  }
}

.textWrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  @media (max-width: 500px) {
    gap: 0px;
  }
}

.title {
  font: 52px/58px var(--visby-medium);
  margin-bottom: 10px;
  color: #00fca3;
  @media (max-width: 500px) {
    font-size: 32px;
  }
}

.description {
  font: 20px/21px var(--visby);
  color: #ffffff;
}

.svgWrapper {
  border-radius: 10px;
  width: 100%;
  @media (max-width: 700px) {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.buttonWrapper {
  display: flex;
  width: 100%;
  gap: 20px;
  :global {
    .ant-btn {
      width: 165px;
      font-size: 13px;
      line-height: 21px;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
  @media (max-width: 550px) {
    flex-direction: column;
  }
}
