.wrapper {
  width: 100%;
  background: #1c1f23;
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
}

.dateWithText {
  display: flex;
  align-items: center;

  @media (max-width: 1279px) {
    margin-left: 17px;
  }
}

.userProfileWrapper {
  @media (max-width: 1279px) {
    display: none;
  }
}

.profileWithSignOut {
  display: flex;
  align-items: center;

  @media (max-width: 1279px) {
    display: none;
  }
}

.popupUserWrapper {
  position: relative;

  @media (max-width: 768px) {
    position: static;
  }
}

.signOut {
  @media (max-width: 768px) {
    display: none;
  }
}

.hallowBadge {
  width: 200px;
  height: 40px;
}
