.container {
  border-radius: 20px;
  background-color: #131314;
  width: 520px;
  padding: 18px 28px 31px 19px;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 640px) {
    max-width: unset;
    width: 100%;
  }
}

.adjustedContainer {
  @media (max-width: 640px) {
    min-height: 250px;
  }
}

.title {
  font: 20px/28px var(--visby-medium);
  color: #00fca3;
  margin: 5px 0 11px 0;
}

.text {
  font: 13px/21px var(--visby);
  color: #ffffff;
}

.textWrapper,
.adjustedTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.textWrapper {
  flex-grow: 1;
  @media (max-width: 640px) {
    max-width: unset;
    width: 100%;
    justify-content: flex-start;
  }
}

.wrapper {
  flex-direction: unset;
  height: 100%;
  @media (max-width: 640px) {
    flex-direction: column;
  }
}

.adjustedTextWrapper {
  margin-top: 10px;
  max-width: 287px;
}

.buttonWrapper {
  display: flex;
  align-self: flex-end;
  width: 165px;
  button {
    span {
      font: 13px var(--visby-bold);
    }
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
  @media (max-width: 640px) {
    align-self: flex-start;
    margin-top: 20px;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}


