.rootWrapper {
  & :global(.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab) {
    padding: 0;
  }
  & :global(.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap) {
    display: inline-block;
  }
  & :global(.ant-tabs-right > .ant-tabs-content-holder) {
    border: none;
  }
  & :global(.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list) {
    background: #131314;
    width: 370px;
    border-radius: 10px;
    padding: 25px 43px 63px;
    margin-bottom: 18px;
    @media (max-width: 768px) {
      padding: 25px 38px 63px;
    }
    @media (max-width: 430px) {
      width: 100%;
    }
  }
  & :global(.ant-tabs-nav .ant-tabs-ink-bar) {
    display: none;
  }
  & :global(.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab) {
    margin: 26px 0 0 0;
  }
  & :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    font: 20px/28px var(--visby-semiBold);
    color: #00fca3;
    text-decoration: none;
  }
  & :global(.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn) {
    font: 20px/28px var(--visby-semiBold);
    color: #ffffff;
    opacity: 0.8;
    text-decoration: none;
  }
  & :global(.ant-tabs > .ant-tabs-nav) {
    flex-direction: column;
    max-width: 370px;
    margin-top: 0px;
    margin-left: 0px;
    display: flex;
    align-self: flex-start;

    @media (max-width: 1280px) {
      height: 100%;
    }
    @media (max-width: 430px) {
      max-width: unset;
      width: 100%;
    }
  }
  & :global(.ant-tabs-extra-content) {
    width: 100%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  & :global(.ant-tabs-tab-btn) {
    font: 20px/28px var(--visby-semiBold);
    color: #ffffff;
    text-decoration: underline;

    &:hover {
      color: #00fca3;
    }
  }
  &
    :global(
      .ant-tabs-right
        > .ant-tabs-content-holder
        > .ant-tabs-content
        > .ant-tabs-tabpane
    ) {
    padding: 0;
    @media (max-width: 1279px) {
      margin-bottom: 50px;
    }
  }
  @media (max-width: 1060px) {
    & :global(.ant-tabs) {
      flex-direction: column;
      overflow: visible;
    }
  }
}

.rootWrapper.secondaryType {
  & :global(.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list) {
    background: none;
  }
  & :global(.ant-tabs-tab-btn) {
    font: 16px/24px var(--visby);
    color: #252342;
    text-decoration: none;
    padding: 5px 16px;
  }
  & :global(.ant-tabs-tab + .ant-tabs-tab) {
    margin-left: 4px;
  }
  & :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    background: #252342;
    border-radius: 50px;
    color: #00fca3;
    font: 16px/24px var(--visby-bold);
    padding: 5px 16px;
  }
  & :global(.ant-tabs-top > .ant-tabs-nav::before) {
    display: none;
  }
  & :global(.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list) {
    padding: 0;
  }
  & :global(.ant-tabs-top > .ant-tabs-nav) {
    margin: 0;
  }
  & :global(.ant-tabs-tab) {
    padding: 0;
  }
  & :global(.ant-tabs-extra-content) {
    display: none;
  }
  @media (max-width: 768px) {
    & :global(.ant-tabs) {
      flex-direction: column;
    }
  }
}

.extraContentWrapper {
  width: 370px;
  @media (max-width: 768px) {
    margin-top: 0;
    padding-top: 0px;
  }

  @media (max-width: 425px) {
    width: unset;
    max-width: 370px;
  }

  @media (max-width: 400px) {
  }
}

.showVideoCourseBanner {
  & :global(.ant-tabs > .ant-tabs-nav) {
    @media (max-width: 1280px) {
      height: 100%;
    }
  }
}
