.container {
  overflow: hidden;
  border-radius: 20px;
  background-color: #131314;
  width: 100%;
  height: 100%;
  padding: 0 0 15px 20px;
  @media (max-width: 1008px) {
    padding-bottom: 0;
    width: 519px;
  }
  @media (max-width: 640px) {
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 360px) {
    padding: 0 20px 0 20px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 174.5px);
  grid-template-rows: 50% 50%;
  @media (max-width: 1008px) {
    grid-template-columns: repeat(2, 259.5px);
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-template-rows: auto;
  }
  height: 100%;
}

.title {
  color: #00fca3;
  font: 21px/28px var(--visby-medium);
  margin-top: 20px;
  grid-column: 1;
  grid-row: 1;
}

.btnWrapper {
  width: 165px;
  grid-column: 1;
  grid-row: 2;
  align-self: flex-end;
  button {
    span {
      font: 13px var(--visby-bold);
    }
  }
  @media (max-width: 1008px) {
    padding-bottom: 20px;
  }
  @media (max-width: 360px) {
    width: 100%;
    padding-bottom: 31px;
    margin: 80px 0 0 0;
  }
}

.iconWrapper {
  position: relative;
  width: 243px;
  height: 189px;
  grid-column: 1/4;
  grid-row: 1/3;
  justify-self: flex-end;
  overflow: hidden;
  border-radius: 20px;
  svg {
    position: absolute;
    top: 65px;
    left: 108px;
    width: 150px;
    height: 150px;
    filter: drop-shadow(0px 0px 54px rgba(0, 252, 163, 0.8));
  }
  @media (max-width: 360px) {
    display: none;
  }
}

.iconWrapper {
  height: 219px;
}
