.container {
  background-color: #131314;
  width: 455px;
  border-radius: 10px;
  position: relative;
  @media (max-width: 510px) {
    width: unset;
    max-width: 455px;
  }
}

.container2 {
  position: absolute;
  border-radius: 10px;
  display: none;
  width: 100%;
  height: 100%;
  background-color: hsla(240, 3%, 8%, 0.726);
  z-index: 999;
  color: #00fca3;
  font-size: 50px;
}

.container2Active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  padding: 15px 20px 25px 27px;
  display: grid;
  grid-template-columns: 100px 308px;
  grid-template-rows: 54px 28px;
  @media (max-width: 510px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 500px) {
    grid-template-rows: auto;
  }
}

.title {
  font: 30px var(--visby-medium);
  color: #ffffff;
  margin-bottom: 5px;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: flex-end;
}

.wrapperWithDarkBackground {
  padding: 31px 0px 16px 27px;
  & > .title {
    margin: 0;
    line-height: 33px;
  }
}

.subTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font: 13px/21px var(--visby);
  color: #131314;
  max-width: 225px;
  background-color: #00fca3;
  border-radius: 20px;
  padding: 6px 9px 6px 9px;
  grid-column: 1/3;
  grid-row: 2;
  @media (max-width: 500px) {
    grid-column: 1/3;
    grid-row: 3;
  }
}

.textWrapper {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 27px 19px 22px 22px;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 510px) {
    height: unset;
    min-height: 210px;
  }
}

.textWrapperWithDarkBackground {
  margin-left: 27px;
  margin-bottom: 27px;
}

.text {
  font: 13px/21px var(--visby);
  color: #131314;
}

.textButtomWrapper {
  :global {
    .ant-btn {
      font-size: 13px;
      line-height: 21px;
    }
  }
}

.buttonWrapper {
  width: 165px;
  @media (max-width: 400px) {
    width: 140px;
  }
  @media (max-width: 360px) {
    width: 110px;
  }
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#000, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#000, 0.15);
      transform: translateY(0);
    }
  }
}

.buttonWrapperWithDarkBackground {
  width: 165px;
  @media (max-width: 400px) {
    width: 140px;
  }
  @media (max-width: 360px) {
    width: 130px;
  }
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.textButtomWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 510px) {
    margin-top: 13px;
  }
}

.time {
  display: flex;
  align-items: flex-end;
  font: 13px var(--visby);
  margin: 0 30px 10px 0;
  color: #131314;
  @media (max-width: 510px) {
    margin: 0 15px 10px 0;
  }
  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
  path {
    fill: #131314;
  }
}

.timeWithDarkBackground {
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  font: 13px var(--visby);
  margin: 0 30px 10px 0;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  path {
    fill: #ffffff;
  }
}

.highlight {
  font: 15px/23px var(--visby);
  color: #ffffff;
  grid-column: 2;
  grid-row: 1;
  padding-left: 58px;
  @media (max-width: 500px) {
    padding: 0px 0 10px 0px;
    grid-column: 1/3;
    grid-row: 2;
  }
}

.highlightTimer {
  color: #00fca3;
}
