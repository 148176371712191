.close {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.highLight {
  font-weight: 600;
}
