.wrapper {
  padding-top: 42px;
  position: relative;
  width: 600px;

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }
}

.highFiveWrapper {
  width: 65px;
  height: 65px;
  position: absolute;
  background-color: #00fca3;
  border-radius: 100px;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardWrapper {
  padding: 55px 22px 27px 22px;
  border-radius: 22px;
  background-color: #ffffff;
  width: 600px;

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }
}

.titleWrapper {
  text-align: center;
  margin: 0 26px 18px 26px;

  @media (max-width: 550px) {
    margin: 0 0 18px 0;
  }
}

.cardTitle {
  font: 27px/41px var(--visby-bold);
  color: #252343;
}

.subTitle {
  font: 22px/28px var(--visby-bold);
  color: #252343;
}

.divider {
  margin-bottom: 18px;
}

.descWrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 26px;

  @media (max-width: 550px) {
    margin: 0 0 18px 0;
  }
}

.cardDesc {
  font: 17px/28px var(--visby-medium);
  color: #252342;
}

.highFiveIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  path {
    fill: #252342;
  }
}

.shareWrapper {
  width: 194px;
  margin-bottom: 12px;
  margin: 14px auto;
  button > button > span {
    color: #252342;
  }
}

.bannerHighFiveIcon {
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 123px;
  height: 123px;
  path {
    fill: #00fca3;
  }
}

.bannerDescWrapper {
  text-align: center;
  margin-top: 153px;
  opacity: 0.99;
}

.bannerDesc {
  font: 27px/29px var(--visby);
  color: #ffffff;
  padding-top: 153px;
}

.thankYouText {
  font: 14px/29px var(--visby);
  color: #ffffff;
  margin-top: 19px;
}

.close {
  position: absolute;
  top: 54px;
  right: 16px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.closeTextWrapper {
  text-align: center;
}

.closeText {
  cursor: pointer;
  border-bottom: 1px solid #252342;
  color: #252342;
  font: 17px var(--visby-medium);
}

.shareBanner {
  position: relative;
  justify-content: center;
  margin: 0 15px;
  overflow: hidden;
  background-color: #0a0a0a;
  padding: 0 44px 23px 44px;
  border-radius: 10px;
}

.logoWrapper {
  display: flex;
  justify-content: center;

  div {
    padding: 0;
  }
}

.codelexLogo {
  position: absolute;
  width: 100%;
  height: 150%;
  transform: rotate(-15deg);
  top: -70px;
  background: transparent;
  right: -5px;

  path {
    fill: rgba(29, 28, 28, 0.6);
  }

  @media (max-width: 550px) {
    width: 110%;
    right: -25px;
  }

  @media (max-width: 503px) {
    width: 110%;
    right: -25px;
    top: -90px;
  }

  @media (max-width: 411px) {
    width: 120%;
    right: -28px;
    top: -115px;
  }

  @media (max-width: 350px) {
    width: 130%;
    right: -32px;
    top: -140px;
  }
}
