.wrapper {
  padding-top: 42px;
  position: relative;
  width: 600px;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.envelopeWrapper {
  width: 65px;
  height: 65px;
  position: absolute;
  background-color: #00fca3;
  border-radius: 100px;
  z-index: 4;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardWrapper {
  padding: 55px 22px 27px 22px;
  border-radius: 22px;
  background-color: #ffffff;
  width: 600px;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.descWrapper {
  display: flex;
}

.cardDesc {
  margin-top: 46px;
  font: 17px/28px var(--visby-medium);
  color: hsla(244, 31%, 20%, 1);
}

.envelopeIcon {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 31px;
  height: 24px;
  path {
    fill: #252342;
  }
}

.comments {
  margin-top: 14px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 5px;
  max-height: 320px;
}

.comment {
  background-color: rgba(41, 47, 55, 0.1);
  padding: 18px 11px 18px 28px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content {
  font: 17px/26px var(--visby-medium);
  color: #000;
}

.date {
  font: 12px/12px var(--visby-medium);
  padding: 6px 12px;
  color: #00fca3;
  background-color: #000;
  max-width: 100px;
  border-radius: 20px;
  text-align: center;
}

.buttonWrapper {
  width: 194px;
  display: flex;
  justify-self: center;
  justify-content: center;
  margin-top: 38px;
  button span {
    font: 13px/21px var(--visby-bold);
  }
}

.close {
  position: absolute;
  top: 70px;
  right: 32px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
