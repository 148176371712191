.wrapper {
  width: 455px;
  background-color: #141415;
  border-radius: 20px;
  padding: 21px 30px 33px 30px;
  @media (max-width: 600px) {
    width: unset;
    max-width: 486px;
    width: 100%;
  }
}

.title {
  color: #ffffff;
  font: 19px var(--visby-semiBold);
}

.linkContainer {
  margin-top: 23px;
}

.linkWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 17px 16px;
  background-color: #262627;
  border-radius: 7px;
  height: 64px;
  @media (max-width: 390px) {
    flex-direction: column;
    gap: 8px;
    height: unset;
  }
}

.actionWrapper {
  display: flex;
  align-items: center;
  @media (max-width: 390px) {
    align-items: center;
  }
}

.iconWrapper {
  width: 30px;
  @media (max-width: 390px) {
    width: unset;
  }
}

.highLight {
  margin-left: 15px;
  font: 16px/11px var(--visby-semiBold);
  color: #ffffff;
  text-transform: capitalize;
  @media (max-width: 390px) {
    margin-left: 8px;
  }
}

.iconGeneric {
  path {
    width: 30px;
    height: 30px;
    fill: #00fca3;
  }
}

.iconDisabled {
  width: 30px;
  height: 30px;
  path {
    fill: #d9d9d9;
  }
}

.iconFacebook {
  path {
    width: 16px;
    fill: #00fca3;
  }
}
.iconFacebookDisalbed {
  path {
    width: 16px;
    fill: #d9d9d9;
  }
}
