.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: 522px) {
    flex-direction: column-reverse;
    gap: 15px;
    width: 100%;
  }
}

.wrapper {
  max-width: 456px;
  @media (max-width: 522px) {
    max-width: unset;
    width: 100%;
  }
}

.title {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
  margin-bottom: 17px;
}

.titleWrapper {
  margin-top: 26px;
}

.descWrapper {
  margin-bottom: 32px;
}

.desc {
  font:13px/21px var(--visby);
  color: #ffffff;
}

.buttonWrapper {
  width: 165px;
  transition: all 0.2s;

  button {
    span {
      font: 13px var(--visby-bold);
    }
  }

  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
  @media (max-width: 460px) {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    position: unset;
  }
}

.iconMobile {
  display: none;
  @media (max-width: 756px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 657px) {
      top: -26px;
    }
  }
}

.icon,
.iconMobile {
  width: 251px;
  height: 251px;
  filter: drop-shadow(0 0 32px #00fca3);
  margin-right: 139px;

  @media (max-width: 1020px) {
    margin-right: 20px;
  }
  @media (max-width: 901px) {
    margin-right: 0;
  }

  @media (max-width: 756px) {
    max-width: 100px;
    max-height: 100px;
  }

  @media (max-width: 689px) {
    display: none;
  }
}

.iconWrapper {
  @media (max-width: 756px) {
    display: none;
  }
}

.link {
  a {
    text-decoration: underline;
  }
}
