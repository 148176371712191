.container {
  display: flex;
  flex-direction: column;
  width: 285px;
  cursor: pointer;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 10px 20px rgba(#fff, 0.2);
  }
  border-radius: 20px;
  overflow: hidden;
}

.textWrapper {
  padding: 16px 16px 15px 15px;
  background-color: #131618;
  border-radius: 20px;
  min-height: 157px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  z-index: 2;
  @media (max-width: 310px) {
    margin-top: -30px;
    min-height: 197px;
    height: unset;
  }
  @media (max-width: 300px) {
    margin-top: -25px;
  }
  @media (max-width: 290px) {
    margin-top: -20px;
  }
}

.title {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
}

.description {
  font: 13px/21px var(--visby);
  color: #adadad;
  margin-top: 15px;
}

.badge {
  position: absolute;
  padding: 2px 20px;
  color: #676769;
  font: 9px/21px var(--visby-semiBold);
  border-radius: 5px;
  z-index: 3;
  background-color: #eaeaea;
  margin-left: 20px;
  margin-top: 12px;
}

.inProcessBadge {
  position: absolute;
  padding: 2px 20px;
  color: #676769;
  font: 9px/21px var(--visby-semiBold);
  border-radius: 5px;
  z-index: 3;
  background-color: #eaeaea;
  margin-left: 20px;
  margin-top: 12px;
}

.secondBadge {
  margin-left: 110px;
}

.comingSoon {
  position: relative;
  pointer-events: none;
}

.comingSoon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 1;
}
