.container {
  border-radius: 20px;
  background-color: #131314;
  width: 369px;
  position: relative;
  overflow: hidden;
  padding: 20px 20px 31px 20px;
  @media (max-width: 1008px) {
    width: 519px;
  }
  @media (max-width: 640px) {
    max-width: unset;
    width: 100%;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 184.5px);
  @media (max-width: 1008px) {
    grid-template-columns: repeat(2, 260px);
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  }
}

.title {
  width: 230px;
  color: #00fca3;
  font: 21px/28px var(--visby-medium);
  grid-column: 1/3;
  grid-row: 1;
  margin-bottom: 14px;
  @media (max-width: 1008px) {
    width: 100%;
  }
}

.btnWrapper {
  width: 165px;
  margin-top: 132px;
  grid-column: 1;
  grid-row: 2;
  align-self: flex-end;
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
  @media (max-width: 1008px) {
    margin-top: 35px;
  }
  @media (max-width: 360px) {
    width: 100%;
  }
}

.btnText {
  font-size: 13px;
  line-height: 21px;
}
