.wrapper {
  margin-top: 42px;
  position: relative;
  width: 600px;
  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }
}

.cardWrapper {
  padding: 50px 22px 30px 22px;
  border-radius: 22px;
  background-color: #ffffff;
  width: 600px;
  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.buttonWrapper {
  max-width: 350px;
  button span {
    font: 13px/21px var(--visby-bold);
  }
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#000, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#000, 0.15);
      transform: translateY(0);
    }
  }
}

.cardDesc {
  margin-top: 46px;
  font: 17px/28px var(--visby-medium);
  color: hsla(244, 31%, 20%, 1);
}

.calendarIconWrapper {
  path {
    stroke: rgba(255, 255, 255, 0.7);
  }
}

.nameConfig {
  padding-top: 10px;
}
