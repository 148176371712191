.wrapper {
  padding-right: 37px;
  padding-bottom: 100px;
  position: relative;
  @media (min-width: 1280px) {
    max-width: 680px;
  }
  @media (max-width: 1060px) {
    padding-bottom: 0;
    padding-right: 0;
  }
  @media (max-width: 768px) {
    padding-top: 34px;
  }
}

.notificationWrapper {
  margin-top: 24px;
}

.titleWithDate {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 26px;
  }
}

.titleBlock {
  @media (max-width: 768px) {
    width: 100%;
  }
}

.title {
  font: 52px/78px var(--visby-semiBold);
  color: #ffffff;
  margin-bottom: 18px;
  @media (max-width: 768px) {
    font: 42px/43px var(--visby-semiBold);
  }
  @media (max-width: 300px) {
    font-size: 38px;
  }
}

.lessonTitle {
  font: 35px/42px var(--visby-semiBold);
  color: #ffffff;
  margin-top: 42px;
  margin-bottom: 39px;
  @media (max-width: 300px) {
    font-size: 30px;
  }
}

.subTitle {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 18px;
  }
}

.text {
  font: 16px/24px var(--visby);
  color: #ffffff;
  margin-bottom: 45px;
  @media (max-width: 768px) {
    font: 14px/22px var(--visby);
  }
}

.videoLabel {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
  margin-bottom: 24px;
}

.videoBlock {
  margin-top: 30px;
  width: 100%;
  height: 0;
  padding-bottom: 56.3%;
  position: relative;
}
.videoBlock iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.tasksGithubBlock {
  margin-top: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 40px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.tasksGithub {
  font: 24px/28px var(--visby-semiBold);
  color: #ffffff;
  margin: 0 42px 0 0;
  @media (max-width: 768px) {
    margin-bottom: 18px;
    color: #ffffff;
  }
}

.link {
  font: 16px/24px var(--visby-bold);
  color: #00fca3;
  margin-bottom: 28px;
  text-decoration: none;
  a {
    color: inherit;
    &:hover {
      color: rgba(0, 252, 163, 0.2);
    }
  }
  @media (max-width: 768px) {
    font: 14px/22px var(--visby);
  }
}

.textWithButton {
  display: flex;
  align-items: flex-start;
  margin-top: 18px;
  margin-bottom: 23px;
  @media (max-width: 768px) {
    margin-top: 40px;
  }
  @media screen and (max-width: 875px) {
    flex-direction: column;
  }
}

.button {
  width: 226px;
  height: 42px;
  @media (max-width: 768px) {
    margin-top: 18px;
    width: 100%;
  }
}

.communicationText {
  max-width: 560px;
  font: 16px/24px var(--visby);
  color: #ffffff;
  margin-right: 60px;
}

.contentAfterVideo {
  h2 {
    margin-top: 36px;
    font: 20px/28px var(--visby-semiBold);
    color: #ffffff;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-bottom: 18px;
    }
  }
  h3 {
    margin-top: 24px;
    font: 20px var(--visby-semiBold);
    color: #ffffff;
  }
  ul li {
    margin-bottom: 9px;
  }
  ul li a {
    color: #00fca3;
    padding: 0;
    text-decoration: underline;
    font: 13px/21px var(--visby);
  }
}

.githubWrapper {
  div {
    max-width: 100%;
  }
}

.lessonDesc {
  font: 20px/28px var(--visby-semiBold);
  color: #00fca3;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 18px;
  }
}
